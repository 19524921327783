angular.module("app")
    .controller("tripsController", ["$scope", "$log", "$timeout", "$stateParams", "Notification", "tripService", "paymentMethodService", "staffService", "travelPolicyService", function ($scope, $log, $timeout, $stateParams, Notification, tripService, paymentMethodService, staffService, travelPolicyService) {
        var vm = this;

        vm.from = undefined;
        vm.to = undefined;
        vm.staff = undefined;
        vm.staffReference = undefined;
        vm.paymentMethodReference = undefined;
        vm.organisationReference = undefined;
        vm.paymentMethod = undefined;
        vm.paymentMethods = [];

        vm.trips = [];

        vm.pagedSet = {
            page: 0
        };

        vm.pageNotReady = vm.disableLoadMore = vm.loadingStaff = true;
        vm.loading = vm.includeInactive = vm.loadingMore = false;

        // exposed functions
        vm.loadMore = loadMore;
        vm.getStaffByName = getStaffByName;
        vm.getPaymentMethodByName = getPaymentMethodByName;
        vm.search = search;

        // init
        function init() {
            clearSearch();

            vm.organisationReference = $stateParams.organisationReference;

            if (!_.isUndefined($stateParams.staffReference)) {
                vm.staffReference = $stateParams.staffReference;
                getStaffFromReference();
            }
            else if (!_.isUndefined($stateParams.paymentMethodReference)) {
                vm.paymentMethodReference = $stateParams.paymentMethodReference;
                getPaymentMethodFromReference();
            } else {
                search();
            }

            getPaymentMethods();
        }

        init();

        function getStaffByName(name) {
            if (!_.isEmpty(name)) {

                params = {
                    name: name,
                    page: 0,
                    includeInactive: false,
                    children: true,
                    organisationReference: vm.organisationReference
                };

                return staffService.listByName(params)
                    .then(function (results) {
                        return results.results;
                    })
                    ['finally'](function () {
                    // nothing so far
                });
            }
        }

        function getPaymentMethods() {

            var params = {
                pageSize: 1000,
                page: 0,
                inactive: false,
                linked: false,
                children: true,
                organisationReference: vm.organisationReference
            };
            return paymentMethodService.list(params)
                .then(function (results) {
                    vm.paymentMethods = results.results;
                })
                ['finally'](function () {
                // nothing so far
            });
        }

        function getPaymentMethodByName(name) {
            if (!_.isEmpty(name)) {
                return paymentMethodService.listByName(name, 0)
                    .then(function (results) {
                        return results.results;
                    })
                    ['finally'](function () {
                    // nothing so far
                });
            }
        }

        function clearSearch() {
            vm.from = moment().subtract(30, 'days').startOf('day').toDate();
            vm.to = moment().endOf('day').toDate();
            vm.staff = undefined;
            vm.paymentMethod = undefined;
        }

        function loadMore() {
            listTrips(vm.pagedSet.page);
        }

        function search() {
            listTrips(0);
        }


        function listTrips(page) {

            if (page === 0) vm.trips = [];

            vm.loadingMore = true;

            vm.loading = true;
            vm.disableLoadMore = true;

            var passengerReference = _.isEmpty(vm.staff) ? undefined : vm.staff.accountReference;

            //placedBy for ingogoFlag is driver therefore unable to select by staff when ingogoFlag
            var placedBy = _.isEmpty(vm.staff) ? undefined : 'PASSENGER';

            var paymentMethodReference = _.isEmpty(vm.paymentMethod) ? undefined : vm.paymentMethod.organisationPaymentMethodReference;

            var fromDate = moment(vm.from).startOf('day').toISOString();
            var toDate = moment(vm.to).endOf('day').toISOString();

            return tripService.list(page, paymentMethodReference, placedBy, passengerReference, fromDate, toDate)
                .then(function (pagedSet) {
                    vm.pagedSet = pagedSet;
                    vm.trips = vm.trips.concat(pagedSet.results);
                    vm.trips = _.filter(vm.trips, function (booking) {
                            var timezoneOffset = moment.parseZone(booking.requestedPickup).utcOffset();
                            booking.requestedPickup = moment(booking.requestedPickup).utcOffset(timezoneOffset);
                            return true;
                    });


                    vm.disableLoadMore = (vm.pagedSet.page >= vm.pagedSet.totalPages);
                })
                ['catch'](function () {
                Notification.error({message: 'Unable to load trips', delay: 5000});
                vm.disableLoadMore = true;
                vm.trips = [];
            })
                ['finally'](function () {
                vm.loading = vm.pageNotReady = vm.loadingMore = false;

            });
        }

        function notifyGenericError() {
            Notification.error({message: 'There was a problem with this page, please try again later.', delay: 5000});
        }

        function getStaffFromReference() {
            return staffService.get(vm.organisationReference, vm.staffReference)
                .then(function (result) {
                    vm.staff = result;
                    search();
                });
        }

        function getPaymentMethodFromReference() {
            return paymentMethodService.get(vm.organisationReference, vm.paymentMethodReference)
                .then(function (result) {
                    vm.paymentMethod = result;
                    search();
                });
        }

        vm.showPolicySummary = function (trip) {
            return travelPolicyService.get(trip.exceptionTravelPolicyReference)
                .then(function (data) {
                    var bookingRef = trip.bookingReference;
                    vm.travelPolicy = data;
                    if (vm.showPolicyDetail) {
                        vm.showPolicyDetail[bookingRef] = true;
                    }
                });
        };
    }]);